<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <div class="misc-inner">
      <div class="w-100 text-center">
        <h2 class="mb-1">İnternete Bağlanın. 🌍 🕵🏻‍♀️</h2>
        <p class="mb-2">İnternete bağlı değilsiniz. Lütfen internet bağlantınızı kontrol edin.</p>
        <b-button variant="primary" class="mb-2 btn-sm-block" :to="{ name: 'dashboard' }"> Ana Sayfa </b-button>
      </div>
    </div>
  </div>
  <!-- / Error page-->
</template>

<script>
import Logo from "@/@core/layouts/components/Logo.vue";

export default {
  components: { Logo },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
</style>
